import { Core } from "@faire/design-tokens";
import styled, { css } from "styled-components";

interface IPropTypes {
  className?: string;
  $width?: number | string;
  $height?: number | string;
  /**
   * ratio = width/height
   */
  $ratio: number;
  $hideBorder?: boolean;
  $objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
}

export const AspectRatioPicture = styled.picture<IPropTypes>`
  display: flex;
  position: relative;
  height: 0;
  flex-shrink: 0;
  ${({ $hideBorder: hideBorder }) =>
    hideBorder
      ? ""
      : css`
          border: 1px solid ${Core.border.muted};
        `}

  width: ${({ $width: width, $height: height, $ratio: ratio }) => {
    if (width) {
      if (typeof width === "number") {
        return css`
          ${width}px
        `;
      }
      return width;
    }

    if (height) {
      if (typeof height === "number") {
        return css`calc(${height}px * ${ratio})`;
      }

      return css`calc(${height} * ${ratio})`;
    }

    throw new Error(
      "neither width or height defined in AspectRatioPicture Component"
    );
  }};
  padding-top: ${({
    $width: width,
    $height: height,
    $ratio: ratio,
    $hideBorder: hideBorder,
  }) => {
    if (width) {
      if (typeof width === "number") {
        return css`calc(${width}px / ${ratio} - ${hideBorder ? "0px" : "2px"})`;
      }

      return css`calc(${width} / ${ratio} - ${hideBorder ? "0px" : "2px"})`;
    }

    if (height) {
      if (typeof height === "number") {
        return css`
          ${height}px
        `;
      }

      return height;
    }

    throw new Error(
      "neither width or height defined in AspectRatioPicture Component"
    );
  }};

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: ${({ $objectFit: objectFit }) => objectFit || "cover"};
    object-position: center;
  }
`;
