import { trackFrontendApisClientSideErrorError } from "@faire/web-api/events/frontendApis/error/clientSideError";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";
import { StrictLocalMsg } from "@faire/web/ui/lib/localization";
import * as React from "react";
import { useEffect } from "react";

import { PageNotFoundTemplate } from "./PageNotFoundTemplate";

export const PageNotFound: React.FC = () => {
  useEffect(() => {
    trackFrontendApisClientSideErrorError(
      "Page not found",
      getGlobalProperty("isNextJS") ? "NEXT_JS" : "SPA",
      getGlobalProperty("originalUrl"),
      getGlobalProperty("processingServer")
    );
  }, []);

  return (
    <PageNotFoundTemplate
      title={
        <StrictLocalMsg
          defaultMessage="Sorry about that!"
          description={{
            text: "Title for a page not found",
            img: "https://cdn.faire.com/fastly/2e95f6752e31970ef6fa49d2ab92aee7a12eac48b889d247f2125dc86b7d4213.png",
          }}
        />
      }
      subtitle={
        <>
          <StrictLocalMsg
            defaultMessage="We can’t find the page you’re looking for."
            description={{
              text: "Subtitle for a page not found",
              img: "https://cdn.faire.com/fastly/2e95f6752e31970ef6fa49d2ab92aee7a12eac48b889d247f2125dc86b7d4213.png",
            }}
          />
          <br />
          <StrictLocalMsg
            defaultMessage="Maybe some retail therapy can help?"
            description={{
              text: "Subtitle for a page not found",
              img: "https://cdn.faire.com/fastly/2e95f6752e31970ef6fa49d2ab92aee7a12eac48b889d247f2125dc86b7d4213.png",
            }}
          />
        </>
      }
      trackingContainerName="page_not_found_category_grid"
    />
  );
};
