import { ICategory } from "@faire/web-api/indigofair/data/ICategory";

const getCategoryByUrlId = (
  categories: ICategory[],
  urlId: string
): ICategory | undefined => {
  return categories.find((category) => category.url_id === urlId);
};

/**
 * Will return the category which most deeply matches the URL IDs provided
 *
 * For example in a tree like so below
 *
 * - Home Decor
 * - - Pillows
 * - - - Throw Pillows
 *
 * urlIds = ["Home Decor", "Pillows", "Throw Pillows"] will return the Throw Pillows category
 * urlIds = ["Home Decor", "Pillows"] will return the Pillows category
 * urlIds = ["Home Decor"] will return the Home Decor category
 * urlIds = ["Home Decor", "Pillows", "Throw Pillows", "Extra"] will return the Throw Pillows category
 * urlIds = ["Home Decor", "Pillows", "Extra"] will return the Pillows category
 * urlIds = ["Home Decor", "Extra"] will return the Home Decor category
 * urlIds = ["Extra"] will return undefined
 */
export const findClosestActiveCategoriesByUrlIds = (
  urlIds: string[],
  categoriesToSearch: ICategory[] | undefined
): ICategory[] => {
  const matchedCategories: ICategory[] = [];

  for (const urlId of urlIds) {
    const category = getCategoryByUrlId(categoriesToSearch ?? [], urlId);
    if (category === undefined) {
      return matchedCategories;
    }

    matchedCategories.push(category);
    categoriesToSearch = category.sub_categories;
  }
  return matchedCategories;
};

/**
 * Will return the category which most deeply matches the URL IDs provided
 *
 * See findClosestActiveCategoriesByUrlIds for more details
 */
export const findClosestActiveCategoryByUrlIds = (
  urlIds: string[],
  categoriesToSearch: ICategory[] | undefined
): ICategory | undefined => {
  const activeCategories = findClosestActiveCategoriesByUrlIds(
    urlIds,
    categoriesToSearch
  );
  return activeCategories[activeCategories.length - 1];
};

/**
 * Returns the category which matches the URL IDs
 *
 * For example in a tree like so below
 *
 * - Home Decor
 * - - Pillows
 * - - - Throw Pillows
 *
 * urlIds = ["Home Decor", "Pillows", "Throw Pillows"] will return the Throw Pillows category
 * urlIds = ["Home Decor", "Pillows"] will return the Pillows category
 * urlIds = ["Home Decor"] will return the Home Decor category
 * urlIds = ["Home Decor", "Pillows", "Throw Pillows", "Extra"] will return undefined
 * urlIds = ["Home Decor", "Pillows", "Extra"] will return undefined
 * urlIds = ["Home Decor", "Extra"] will return undefined
 * urlIds = ["Extra"] will return undefined
 */
export const findActiveCategoryByUrlIds = (
  urlIds: string[],
  categoriesToSearch: ICategory[] | undefined
): ICategory | undefined => {
  const matchedCategories = findClosestActiveCategoriesByUrlIds(
    urlIds,
    categoriesToSearch
  );
  if (matchedCategories.length === urlIds.length) {
    return matchedCategories[matchedCategories.length - 1];
  }
  return undefined;
};
