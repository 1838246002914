"use client";

import { route as bounceRoute } from "@faire/web-api/routes/bounce";
import { RouteMatcher } from "@faire/web/common/routes/RouteMatcher";
import { usePathname } from "next/navigation";
import React from "react";

import { HardRefresh } from "@faire/retailer/app/_lib/routing/HardRefresh";
import { PageNotFound } from "@faire/retailer/components/PageNotFound/PageNotFound";

const Default = () => {
  const pathname = usePathname();
  const match = new RouteMatcher([bounceRoute]).match(pathname);

  // Next.js is not supposed to handle /bounce route, so we need to hard refresh
  // /bounce route is handled by backend-monolith, look for bounce.html
  if (match) {
    return <HardRefresh />;
  }
  return <PageNotFound />;
};
export default Default;
