import { CATEGORY_PATH_IDENTIFIER_DELIMITER } from "@faire/retailer-shared/categories/pathIdentifier";
import { ICategory } from "@faire/web-api/indigofair/data/ICategory";
import { isTupleOfLengthGreaterThanOrEqual } from "@faire/web/common/typescriptUtils";

import { IUrlId, toUrlId } from "@faire/retailer/models/Category/IUrlId";

/**
 * Will give the url ids of the category which most deeply matches the path identifier provider
 * or undefined if no match is found
 *
 * See findClosestActiveCategoriesByPathIdentifier for more details on the logic
 */
export const findClosestActiveCategoryUrlIdsByPathIdentifier = (
  pathIdentifier: string,
  categoriesToSearch: ICategory[] | undefined
): [IUrlId, ...IUrlId[]] | undefined => {
  const activeCategories = findClosestActiveCategoriesByPathIdentifier(
    pathIdentifier,
    categoriesToSearch
  );
  if (!isTupleOfLengthGreaterThanOrEqual(activeCategories, 1)) {
    return undefined;
  }
  return categoriesToUrlIds(activeCategories);
};

const findCategoryByPrefixedPathIdentifier = (
  pathIdentifier: string,
  categoriesToSearch: ICategory[] | undefined = []
): ICategory | undefined => {
  return categoriesToSearch?.find(({ path_identifier }) => {
    if (path_identifier?.length === pathIdentifier.length) {
      return path_identifier === pathIdentifier;
    }

    const partialPath = pathIdentifier.slice(0, path_identifier?.length);

    return (
      path_identifier === partialPath &&
      pathIdentifier[path_identifier?.length] ===
        CATEGORY_PATH_IDENTIFIER_DELIMITER
    );
  });
};

/**
 * Will return the category which most deeply matches the path identifier provider
 *
 * For example in a tree like so below
 *
 * - Home Decor
 * - - Bath
 * - - - Towels
 *
 * pathIdentifier = "home_living|bath|towels" will return the Towels category
 * pathIdentifier = "home_living|bath" will return the Bath category
 * pathIdentifier = "home_living" will return the Home Decor category
 * pathIdentifier = "home_living|bath|towels|extra" will return the Towels category
 * pathIdentifier = "home_living|bath|extra" will return the Bath category
 * pathIdentifier = "home_living|extra" will return the Home Decor category
 * pathIdentifier = "extra" will return the empty array category
 */
export const findClosestActiveCategoriesByPathIdentifier = (
  pathIdentifier: string,
  categoriesToSearch: ICategory[] | undefined = []
): ICategory[] => {
  const category = findCategoryByPrefixedPathIdentifier(
    pathIdentifier,
    categoriesToSearch
  );

  if (category === undefined) {
    return [];
  }
  const subcategories = category?.sub_categories;

  if (subcategories === undefined || subcategories.length === 0) {
    return [category];
  } else {
    return [
      category,
      ...findClosestActiveCategoriesByPathIdentifier(
        pathIdentifier,
        subcategories
      ),
    ];
  }
};

/**
 * Returns the category whose path identifier exactly matches the path identifier provided
 * in the category tree provided
 *
 * @param pathIdentifier
 * @param categoriesToSearch
 * @returns
 */
export const findActiveCategoryByPathIdentifier = (
  pathIdentifier: string,
  categoriesToSearch: ICategory[] | undefined
): ICategory | undefined => {
  const closestActiveCategories = findClosestActiveCategoriesByPathIdentifier(
    pathIdentifier,
    categoriesToSearch
  );
  const lowestLevelActiveCategory =
    closestActiveCategories[closestActiveCategories.length - 1];
  if (lowestLevelActiveCategory?.path_identifier === pathIdentifier) {
    return lowestLevelActiveCategory;
  } else {
    return undefined;
  }
};

// Map a tuple of categories to a tuple of IUrlIds
const categoriesToUrlIds = (
  categories: [ICategory, ...ICategory[]]
): [IUrlId, ...IUrlId[]] => {
  return categories.map((category) => toUrlId(category.url_id ?? "")) as [
    IUrlId,
    ...IUrlId[]
  ];
};
