// Special Categories
export const ALL_BRANDS_URL_ID = "All Brands";

// Special C1 Categories
export const BESTSELLERS_CATEGORY_URL_ID = "Bestsellers";
export const SALE_CATEGORY_URL_ID = "Sale";
export const EUROPEAN_BRANDS_URL_ID = "European Brands";
export const MOTHERS_DAY_SHOP_CATEGORY_URL_ID = "Mother's Day Shop";
export const NEW_BRANDS_CATEGORY_URL_ID = "New Brands";
export const NEW_PRODUCTS_CATEGORY_URL_ID = "New Products";
export const PLUS_SIZE_CATEGORY_URL_ID = "Plus Size";
export const SHOWROOM_CATEGORY_URL_ID = "Showroom";

// Special subcatgories
export const ECO_FRIENDLY_SUBCATEGORY_URL_ID = "Eco-friendly";
export const NEW_ARRIVALS_SUBCATEGORY_URL_ID = "New Arrivals";
export const FOLLOWING_BRANDS_CATEGORY_URL_ID = "Following Brands";

// Featured Categories
export const ALL_EUROPEAN_BRANDS_URL_ID = "All European Brands";
export const EXCLUSIVES_CATEGORY_URL_ID = "Insider Early Access";
export const FEATURED_NEW_ARRIVALS_CATEGORY_URL_ID = "New";
export const JUST_FOR_YOU_CATEGORY_URL_ID = "Just For You";
export const TOP_SELLERS_CATEGORY_URL_ID = "Top Sellers";

// Product based categories
export const FOOD_AND_DRINK_CATEGORY_URL_ID = "Food & Drink";
